import './css/privacyNotice.css';
import LogoHome from "../asset/svg/LogoHome";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

export default function PrivacyNotice(props)
{
    const history = useHistory();

    useEffect(()=>{
        window.scrollTo(0,0);
    });
  
    return (
        <div>
                    <div className="privacyNotice_Background_Logo_Container">
                        <div
                        className="LogoContainer"
                        onClick={()=> history.goBack()}>
                        <LogoHome />
                        </div>
                    </div>
                    <div className="privacyNotice_Container">
                        <div className="avia_textblock  " itemProp="text">
                          <h1>Datenschutzerklärung für Kartenbanking (App und Webanwendung)</h1>
                          <p>
                            <strong>Stand Dezember 2024</strong>
                          </p>
                          <p>
                            Bei Benutzung der App/Webanwendung werden bestimmte personenbezogene Daten
                            erhoben und verarbeitet. Der Schutz dieser Daten ist für uns von zentraler
                            Bedeutung. Die Verarbeitung Ihrer Daten erfolgt im Einklang mit den
                            anwendbaren gesetzlichen Vorschriften.
                          </p>
                          <p>Verantwortliche Stelle ist:</p>
                          <p>
                            S-Kreditpartner GmbH
                            <br />
                            Prinzregentenstraße 25
                            <br />
                            10715 Berlin
                          </p>
                          <p>
                            Tel. 030 / 620 080 400
                            <br />
                            Fax 030 / 620 080 401
                          </p>
                          <p>
                            E-Mail{" "}
                            <a href="mailto:kartenservice@s-kreditpartner.de">
                              kartenservice@s-kreditpartner.de
                            </a>
                          </p>
                          <p>
                            <strong>Umfang der Datenverarbeitung</strong>
                            <br />
                          </p>
                          <p>
                            Wenn Sie die App oder die Webanwendung benutzen, werden personenbezogene
                            Daten erhoben, gespeichert und zur Ausführung der jeweiligen Dienste
                            verarbeitet.
                          </p>
                          <p>
                            Bei den Daten handelt es sich um die von Ihnen uns gegenüber angegebenen
                            Stammdaten wie Ihren Namen (inkl. Anrede und Titel), Ihr Geschlecht, Ihr
                            Geburtsdatum, Ihre E-Mail Adresse, Ihre Telefonnummer sowie Ihre Anschrift.
                            Außerdem verarbeiten wir im Zusammenhang mit Ihrer Nutzung der Funktionen
                            der App Ihre FlexiGeld ID, Ihre Zugangsdaten (z.B. die vierstellige
                            Karten-PIN), Ihre Zahlungsdaten (z.B. IBAN, Kartenprüfnummer, Ablaufdatum
                            der Karte); aber auch Transaktionsdaten wie z.B. Namen, Daten, Beträge,
                            Kartenlimits, Ratenhöhe und weitere Informationen, die von Finanzinstituten
                            oder Händlern im Zusammenhang mit Transaktionen bereitgestellt werden.
                          </p>
                          <p>
                            Im Zusammenhang mit der Authentifizierung in der App verarbeiten wir weder
                            Ihr Foto noch sonstige biometrische Daten, auf denen die von Ihnen
                            eingesetzte Authentifizierungsfunktion (etwa Gesichts- oder
                            Fingerabdruckerkennung) beruht. Es wird lediglich bei vorliegender
                            Zustimmung Ihrerseits der von Ihrem Gerät bereitgestellte Dienst zur
                            Gesichts- oder Fingerabdruckerkennung verwendet. Unsere App verarbeitet
                            dabei nur das erfolgreiche oder nicht erfolgreiche Ergebnis der Prüfung.
                          </p>
                          <p>
                            Beim Verwenden der App und der Webanwendung werden außerdem bestimmte
                            technische und gerätebezogene Daten automatisch erhoben und verarbeitet,
                            nämlich:
                          </p>
                          <ul>
                            <li>IP-Adresse</li>
                            <li>Datum, Uhrzeit</li>
                            <li>Gerätekennungen (nur bei App-Nutzung)</li>
                            <li>Land, Sprache</li>
                          </ul>
                          <p>
                            Die Nutzung der App ohne Verarbeitung dieser Daten ist technisch leider
                            nicht möglich.
                          </p>
                          <p>
                            <strong>Nutzungszweck und Rechtfertigung nach der DSGVO</strong>
                            <br />
                          </p>
                          <p>
                            Soweit dies nicht anders angegeben ist, erfolgt die Datenverarbeitung zu dem
                            Zweck, Ihnen die Nutzung der App und der Webanwendung und ihrer
                            Funktionaltäten zu ermöglichen und beruht damit auf Art. 6 Abs. 1 S.1. lit.
                            b DSGVO.
                          </p>
                          <p>
                            Verarbeitungen zur automatisierten Entscheidungsfindung einschließlich
                            Profiling finden nicht statt.
                          </p>
                          <p>
                            <strong>Auftragsverarbeitung</strong>
                            <br />
                          </p>
                          <p>
                            Wir geben Ihre personenbezogenen Daten nur in engem Umfang an unsere
                            Dienstleister weiter. Diese werden von uns als Auftragsverarbeiter zur
                            Erbringung technischer Leistungen eingesetzt. Dies sind in Deutschland
                            ansässige Anbieter von Rechenzentrums- und sonstigen IT Leistungen, die wir
                            für den Betrieb und zur Wartung der App nutzen.
                          </p>
                          <p>
                            Die App nutzt außerdem Google Firebase Komponenten, welche von Google
                            Ireland Limited und ihren Konzerngesellschaften (nachfolgend gemeinsam
                            "Google") angeboten werden. Von Google werden dabei durch die von uns
                            eingesetzten Firebase Komponenten Cloud-Messaging und Crashlytics
                            personenbezogene Daten in Form von Gerätekennungen (Firebase Installation ID
                            und Crashlytics Installation UUID) sowie Befunddaten zu technischen
                            Störungen verwendet, um zu bestimmen, an welche Geräte bei Nutzung der
                            App-Funktionen Nachrichten zu übermitteln sind bzw. um technische Störungen
                            zu analysieren und beheben zu können.
                          </p>
                          <p>
                            Google Firebase ist nach anerkannten Datenschutz- und Sicherheitsstandards
                            zertifiziert. Die personenbezogenen Daten werden von uns an Google als
                            Auftragsverarbeiter weitergegeben. Sofern Ihre personenbezogenen Daten dabei
                            in Länder außerhalb der EU bzw. des EWR, insbesondere in die USA,
                            übermittelt werden, stellen wir durch entsprechende Maßnahmen sicher, dass
                            diese auf dem gleichen Schutzniveau wie innerhalb der EU verarbeitet werden.
                            Dies kann über entsprechende Datenübermittlungsvereinbarungen erfolgen,
                            wobei wir die durch die Europäische Kommission genehmigten
                            Standardvertragsklauseln und gegebenenfalls weitere Sicherheitsvorkehrungen
                            anwenden.
                          </p>
                          <p>
                            <strong>Aufbewahrungsfristen</strong>
                            <br />
                          </p>
                          <p>
                            Die erhobenen Daten werden gelöscht, sobald die Daten für den Zweck der
                            Verarbeitung nicht mehr erforderlich sind; spätestens aber innerhalb eines
                            Monats, nachdem Sie die App von Ihrem Gerät gelöscht haben.
                          </p>
                          <p>
                            <strong>Datensicherheit</strong>
                            <br />
                          </p>
                          <p>
                            Ihre personenbezogenen Daten werden unter Anwendung aktueller, strenger
                            Sicherheitsstandards gegen unbefugten Zugriff, Verlust und Veränderung
                            geschützt. Dazu treffen wir und unsere Auftragsverarbeiter umfangreiche
                            technische und organisatorische Sicherheitsvorkehrungen, die mindestens den
                            gesetzlichen Vorgaben entsprechen.
                          </p>
                          <p>
                            <strong>Ihre Rechte</strong>
                            <br />
                          </p>
                          <p>
                            Sie haben das Recht auf <strong>Auskunft</strong> (Art. 15 DSGO),
                            <strong>Berichtigung</strong> (Art. 16 DSGVO), <strong>Löschung</strong>
                            (Art. 17 DSGVO), <strong>Einschränkung der Verarbeitung</strong> (Art. 18
                            DSGVO) sowie <strong>Übertragung</strong> Ihrer Daten (Art. 20 DSGVO). Ihre
                            Auskunfts- und Löschungsrechte sind nach Art. 34/35 DSGVO beschränkt. Soweit
                            eine Verarbeitung Ihrer personenbezogenen Daten auf Basis von Art. 6 Abs. 1
                            S.1 lit. f) DSGVO erfolgt, können sie dieser unter den in Art. 21 DSGVO
                            genannten Voraussetzungen <strong>widersprechen</strong>.
                          </p>
                          <p>
                            Zur Geltendmachung Ihrer Betroffenenrechte oder bei Beschwerden zur
                            Verarbeitung Ihrer Daten können Sie sich jederzeit an die oben genannte
                            verantwortliche Stelle unter den dort genannten Kontaktinformationen wenden.
                            Wir empfehlen hierzu die postalische Zusendung.
                          </p>
                          <p>
                            Darüber hinaus haben Sie die Möglichkeit, Beschwerden über die Verarbeitung
                            Ihrer personenbezogenen Daten durch uns bei der zuständigen
                            Datenschutzaufsichtsbehörde vorzubringen. Die für uns zuständige
                            Datenschutzaufsichtsbehörde ist:
                          </p>
                          <p>
                            Berliner Beauftragte für Datenschutz und Informationsfreiheit
                            <br />
                            Friedrichstr. 219
                            <br />
                            10969 Berlin
                            <br />
                            Tel.: +49 30 13889-0
                            <br />
                            E-Mail: mailbox@datenschutz-berlin.de
                            <br />
                          </p>
                          <p>
                            <strong>Kontakt</strong>
                            <br />
                          </p>
                          <p>
                            Bei Fragen zum Datenschutz wenden Sie sich gerne an unseren
                            Datenschutzbeauftragten unter:
                          </p>
                          <p>
                            S-Kreditpartner GmbH
                            <br />
                            Datenschutzbeauftragter
                            <br />
                            Postfach 11 05 48
                            <br />
                            10835 Berlin
                          </p>
                          <p>E-Mail: datenschutzbeauftragter@s-kreditpartner.de</p>
                          <div className="avia_textblock  " itemProp="text">
                            <h1>Impressum</h1>
                            <p>
                              <strong>Stand Dezember 2024</strong>
                            </p>
                            <p>
                              <strong>Anbieterkennzeichnung</strong>
                              <br />
                              S-Kreditpartner GmbH
                              <br />
                              Prinzregentenstraße 25
                              <br />
                              10715 Berlin
                            </p>
                            <p>
                              Tel. 030 620 080 8000
                              <br />
                              Fax 030 620 080 8001
                              <br />
                              E-Mail{" "}
                              <a href="mailto:info@s-kreditpartner.de">info@s-kreditpartner.de</a>
                            </p>
                            <p>
                              <a href="https://www.s-kreditpartner.de/content/dam/mandant-s-kreditpartner/downloads/agb.pdf">
                                Allgemeine Geschäftsbedingungen
                              </a>
                            </p>
                            <p>
                              <strong>Postanschrift</strong>
                              <br />
                              S-Kreditpartner GmbH
                              <br />
                              Postfach 11 05 48
                              <br />
                              10835 Berlin
                            </p>
                            <p>
                              BLZ: 10050020
                              <br />
                              BIC: SKPADEB1XXX
                            </p>
                            <p>
                              <strong>Handelsregister und -nummer</strong>
                              <br />
                              Amtsgericht Charlottenburg/Berlin, Reg.-Nr.: HRB 134899 B
                            </p>
                            <p>
                              <strong>Umsatzsteueridentifikationsnummer</strong>
                              <br />
                              DE 277153022
                            </p>
                            <p>
                              <strong>Geschäftsführer</strong>
                              <br />
                              Heinz-Günter Scheer
                              <br />
                              Jan Welsch
                            </p>
                            <p>
                              <strong>Vorsitzender des Aufsichtsrates</strong>
                              <br />
                              Kai Ostermann
                            </p>
                            <p>
                              <strong>Zuständige Aufsichtsbehörde für die Zulassung</strong>
                              <br />
                              Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)
                              <br />
                              Graurheindorfer Straße 108
                              <br />
                              53117 Bonn
                            </p>
                            <p>
                              <strong>Zuständige Aufsichtsbehörde für den Verbraucherschutz</strong>
                              <br />
                              Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)
                              <br />
                              Graurheindorfer Straße 108
                              <br />
                              53117 Bonn
                            </p>
                            <p>und</p>
                            <p>
                              Marie-Curie-Str. 24-28
                              <br />
                              60439 Frankfurt am Main
                              <br />
                              www.bafin.de
                            </p>
                            <p>
                              <strong>Zuständige Beschwerdestelle</strong>
                              <br />
                              S-Kreditpartner GmbH
                              <br />
                              Service-Center
                              <br />
                              Postfach 11 05 27
                              <br />
                              10838 Berlin
                              <br />
                              E-Mail{" "}
                              <a href="mailto:info@s-kreditpartner.de">
                                qualitaetsmanagement@s-kreditpartner.de
                              </a>
                            </p>
                            <p>
                              <a href="https://www.s-kreditpartner.de/content/dam/mandant-s-kreditpartner/downloads/beschwerdegrundsaetze.pdf">
                                Beschwerdegrundsätze
                              </a>
                            </p>
                            <p>
                              <strong>Schlichtungsstelle</strong>
                              <br />
                              Bei Streitigkeiten mit uns haben Sie die Möglichkeit, die
                              Schlichtungsstelle beim Deutschen Sparkassen- und Giroverband zu
                              kontaktieren. Das Anliegen richten Sie bitte schriftlich an:
                            </p>
                            <p>
                              Deutscher Sparkassen- und Giroverband
                              <br />
                              Schlichtungsstelle
                              <br />
                              Charlottenstraße 47
                              <br />
                              10117 Berlin
                            </p>
                            <p>
                              Online erreichen Sie den Deutschen Sparkassen- und Giroverband (DSGV)
                              unter{" "}
                              <a href="https://www.dsgv.de/de/ueber-uns/schlichtungsstelle/index.html" target={'_blank'}>
                                https://www.dsgv.de/de/ueber-uns/schlichtungsstelle/index.html
                              </a>
                            </p>
                            <p>
                              Näheres regelt die „Verfahrensordnung für die außergerichtliche
                              Schlichtung von Kundenbeschwerden für die Institute der
                              Sparkassen-Finanzgruppe“, die auf Wunsch zur Verfügung gestellt wird.
                            </p>
                            <p>
                              Die S-Kreditpartner GmbH nimmt am Streitbeilegungsverfahren vor dieser
                              anerkannten Verbraucherschlichtungsstelle teil.
                            </p>
                            <p>
                              <strong>Schlichtungsstelle für Versicherungsvermittlung</strong>
                              <br />
                              Versicherungsombudsmann e. V.
                              <br />
                              Postfach 080632
                              <br />
                              10006 Berlin
                              <br />
                              Tel. +49 800 369 600 0<br />
                              Fax +49 800 369 900 0<br />
                              <a href="https://www.versicherungsombudsmann.de/" target={'_blank'}>
                                www.versicherungsombudsmann.de
                              </a>
                            </p>
                            <p>
                              <strong>Zuständige Aufsichtsbehörde für Versicherungsvermittlung</strong>
                              <br />
                              Industrie- und Handelskammer Berlin
                              <br />
                              Fasanenstr. 85
                              <br />
                              10623 Berlin
                              <br />
                              Tel. 030 315 100
                              <br />
                              <a href="https://www.ihk-berlin.de/" target={'_blank'}>www.ihk-berlin.de</a>
                            </p>
                            <p>
                              Registernummer:
                              <br />
                              D-F5HW-TB9JU-1
                            </p>
                            <p>
                              Versicherungsvermittlerregister, abrufbar unter:
                              <br />
                              <a href="https://www.vermittlerregister.info/" target={'_blank'}>
                                www.vermittlerregister.info
                              </a>{" "}
                              (DIHK Deutscher Industrie- und Handelskammertag e. V., Breite Straße 29,
                              10178 Berlin, Tel. 0180 500 585 0 (14 ct/Min. aus dem deutschen Festnetz,
                              höchstens 42 ct/Min. aus Mobilfunknetzen)
                            </p>
                            <p>
                              Tätigkeitsart:
                              <br />
                              Die S-Kreditpartner GmbH ist Versicherungsvertreter mit Erlaubnis nach §
                              34d Abs. 1 GewO und kooperiert mit folgenden Versicherern:
                            </p>
                            <ul>
                              <li>Bayern-Versicherung Lebensversicherung Aktiengesellschaft</li>
                              <li>ProTect Versicherung AG</li>
                              <li>Provinzial Rheinland Lebensversicherung AG</li>
                              <li>Provinzial Rheinland Versicherung AG</li>
                              <li>Gothaer Lebensversicherung AG</li>
                              <li>neue leben Lebensversicherung AG</li>
                              <li>neue leben Unfallversicherung AG</li>
                            </ul>
                            <p>
                              Kundeninformation nach § 11 der Verordnung über die
                              Versicherungsvermittlung und- beratung:{" "}
                              <a href="https://www.s-kreditpartner.de/content/dam/mandant-s-kreditpartner/downloads/eu-visitenkarte.pdf">
                                EU-Visitenkarte
                              </a>
                            </p>
                            <p>
                              Berufsrechtliche Regelungen:
                              <br />
                            </p>
                            <ul>
                              <li>§ 34 d GewO,</li>
                              <li>§§ 59-68 VVG und</li>
                              <li>VersVermV.</li>
                            </ul>
                            <p />
                            <p>
                              Die berufsrechtlichen Regelungen können über die vom Bundesministerium der
                              Justiz und von der juris GmbH betriebene Webseite unter{" "}
                              <a href="https://www.gesetze-im-internet.de/" target={'_blank'}>
                                www.gesetze-im-internet.de
                              </a>{" "}
                              eingesehen und abgerufen werden.
                            </p>
                            <p>
                              <strong>Europäische Online-Streitbeilegungsplattform:</strong>
                              <br />
                              Es besteht die Möglichkeit zur Online-Streitbeilegung gemäß Art. 14 Abs. 1
                              und 2 ODR-VO über die von der EU-Kommission bereitgestellte
                              Online-Plattform zur Online-Streitbeilegung (OS-Plattform) unter{" "}
                              <a href="https://ec.europa.eu/consumers/odr" target={'_blank'}>
                                https://ec.europa.eu/consumers/odr
                              </a>
                              . Die Online-Streitbeilegungsplattform können Verbraucher für die
                              außergerichtliche Beilegung von Streitigkeiten aus online abgeschlossenen
                              Kauf- oder Dienstleistungsverträgen nutzen.
                            </p>
                            <p>
                              <strong>Rechtliche Hinweise</strong>
                              <br />
                            </p>
                            <p>
                              Alle in unseren Internetseiten enthaltenen Angaben und Informationen
                              wurden von der S-Kreditpartner GmbH sorgfältig recherchiert und geprüft.
                              Für die Richtigkeit, Vollständigkeit und Aktualität kann die
                              S-Kreditpartner GmbH jedoch keine Gewähr übernehmen. Die hier enthaltenen
                              Aussagen sind nicht als Angebot oder Empfehlung bestimmter Produkte zu
                              verstehen.
                            </p>
                            <p>
                              Die Internetseiten der S-Kreditpartner GmbH können ohne ihr Wissen von
                              dritter Seite mittels »Hyperlinks« verbunden werden. Die S-Kreditpartner
                              GmbH übernimmt keine Verantwortung für Darstellungen oder Inhalte, die
                              über Verbindungen von Webseiten Dritter zur S-Kreditpartner GmbH
                              entstehen.
                            </p>
                            <p>
                              Wegen der besseren Lesbarkeit wird auf die Ausweisung der weiblichen und
                              männlichen Form verzichtet und ausschließlich die Kurzform (männlich)
                              gewählt. Gemeint ist jedoch stets sowohl die weibliche als auch die
                              männliche Form.
                            </p>
                            <p>
                              Inhalt und Struktur der Webseiten der S-Kreditpartner GmbH sind
                              urheberrechtlich geschützt. Die Vervielfältigung von Informationen oder
                              Daten, insbesondere die Verwendung von Texten, Textteilen oder
                              Bildmaterial, bedarf der vorherigen Zustimmung der S-Kreditpartner GmbH.
                            </p>
                            </div>
           </div>
          </div>
        </div>
    );

     
}